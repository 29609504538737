import styled from "styled-components"
import { colors } from "./colors"
import { motion } from "framer-motion"

export const OutlineButton = styled(motion.button)`
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 20px;
  font-size: 16px;
  color: ${colors.base};
  background: none;
  border: 2px solid ${colors.base};
  font-weight: 400;
  width: max-content;
  font-family: "PhantomSans";
  transition: 0.2s ease;
  outline: none;
  transition: 0.15s ease;

  :hover {
    background: ${colors.base};
    color: white;
  }
`

export const MainButton = styled.button`
  cursor: pointer;
  border-radius: 5px;
  padding: 12px 20px;
  border: none;
  font-weight: 400;
  font-size: 18px;
  /* width: max-content; */
  width: 100%;
  background: ${colors.base};
  color: white;
  font-family: "PhantomSans";
  transition: 0.2s ease;

  :disabled {
    background: #707070;
  }
`
export const TextButton = styled.button`
  cursor: pointer;
  border-radius: 10px;
  /* padding: 12px 20px; */
  border: none;
  outline: none;
  background: none;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  text-decoration: underline;
  /* width: max-content; */
  width: 100%;
  color: ${colors.base};
  font-family: "PhantomSans";
`
